var ccc = ccc || {};
( function () {
    'use strict';
    jQuery( window ).on( 'load', function () {
        ccc.initOnLoad();
    } );
    ccc.initOnLoad = function () {
        ccc.svg.init();
        ccc.navigationSetTarget.init();
        ccc.modalOpenPageLoad.init();
    }
    ccc.navigationSetTarget = {
        init: function () {
            jQuery( '.e-header .nav-link[href^="http"]' ).attr( 'target', '_blank' );
        }
    };
    ccc.modalOpenPageLoad = {
        init: function () {
            jQuery('#cc-job-modal-js').modal('show');
        }
    };
    ccc.svg = {};
    ccc.svg.init = function () {
        jQuery( 'img.svg' ).each( function () {
            var $img = jQuery( this );
            var imgID = $img.attr( 'id' );
            var imgClass = $img.attr( 'class' );
            var imgURL = $img.attr( 'src' );

            jQuery.get( imgURL, function ( data ) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery( data ).find( 'svg' );

                // Add replaced image's ID to the new SVG
                if ( typeof imgID !== 'undefined' ) {
                    $svg = $svg.attr( 'id', imgID );
                }
                // Add replaced image's classes to the new SVG
                if ( typeof imgClass !== 'undefined' ) {
                    $svg = $svg.attr( 'class', imgClass + ' replaced-svg' );
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr( 'xmlns:a' );

                // Replace image with new SVG
                $img.replaceWith( $svg );

            }, 'xml' );
        } );
    };
    jQuery( window ).scroll( function () {
        var scroll = jQuery( window ).scrollTop();
        if (scroll >= 0.5 * jQuery(window).height()) {
            jQuery( ".e-header" ).addClass( "cc-header-scroll-background" );
        }
        else {
            jQuery( ".e-header" ).removeClass( "cc-header-scroll-background" );
        }
    } );
    jQuery('div[data-slide-to]').on('click', function(){
        jQuery('#cc-project-image-modal').carousel($(this).data('slide-to'));
    });
}() );